import React from 'react';
import { Field } from 'react-final-form'

export default ({autoComplete, label, name, required, style, type}) => (
  <div className="control control--block" style={style}>
    <label htmlFor={name} className="control__label">{label}{required ? "*" : null}</label>
    <Field
      aria-required={!!required}
      autoComplete={autoComplete ? autoComplete : null}
      className="control__input"
      component="input"
      id={name}
      name={name}
      required={!!required}
      style={{lineHeight: 'normal'}}
      type={type}
    />
  </div>
);
