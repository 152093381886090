import Img from 'gatsby-image'
import { graphql, navigate } from 'gatsby'
import React from 'react'
import { Form, Field } from 'react-final-form'
import ReCAPTCHA from "react-google-recaptcha"

import Layout from '../components/layout'
import SEO from '../components/seo'
import TentField from '../components/TentField'

const onSubmit = (values) => {
  if (values['recaptcha'] == null) {
    console.log('Failed to submit form, ReCaptcha missing');
    setTimeout(() => {
      alert('Please complete the ReCAPTCHA (I\'m not a robot) prompt at the bottom of the form.');
    })
  } else {
    const json = JSON.stringify({ 'form_data': values });
    const xh = new XMLHttpRequest();

    xh.onreadystatechange = () => {
      if (xh.readyState === 4) {
        if (xh.status === 200) {
          const response = JSON.parse(xh.responseText);

          if (response['success'] === true) {
            navigate('/request-info/confirmation/');
          }
        } else {
          alert('An error occurred while submitting the form. Please try again.');
        }
      }
    }

    xh.open('POST', 'https://exipg2yo2c.execute-api.us-east-1.amazonaws.com/default/handle-request-info-form', true)
    xh.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xh.setRequestHeader("X-API-Key", "Ye8YZz7woz9A7BmHjbMaIavrkGTHFhwf4f73PT2Y");
    xh.send(json);
  }
};

const setReCaptcha = (args, state, { changeValue }) => {
  console.log('setReCaptcha')
  changeValue(state, 'recaptcha', () => args[0])
}

const clearReCaptcha = (args, state, { changeValue }) => {
  console.log('clearing reCaptcha')
  changeValue(state, 'recaptcha', () => null)
}

export default ({ data }) => {
  return (<Layout>
    <SEO title="Request Info" keywords={[]}/>

    <div className='container'>
      <div className="grid">
        <div className="grid__column">
          <h1 className='title title--xxxl color-primary'>
            Request Info
          </h1>
        </div>
      </div>

      <Form
        mutators={{clearReCaptcha, setReCaptcha}}
        onSubmit={onSubmit}
        render={( {handleSubmit, invalid, form, pristine, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid">
              <div className="grid__column grid__column--12 grid__column--4--md">
                <Img
                  className='margin-sm margin-y'
                  fluid={data.iBrochure.childImageSharp.fluid}
                  style={{maxWidth: '268px'}}
                />
              </div>
              <div className="grid__column grid__column--12 grid__column--8--md">
                  <p className="type-bold margin-sm margin-y">Please fill
                  out the form below to request further information or
                  technical information on The Infinity Structural System.</p>

                  <TentField label='Name' name='name' type='text' required={true} />

                  {/* Honeypot to help prevent spam in conjunction with reCAPTCHA */}
                  <TentField
                    autoComplete="off"
                    label='Skip This'
                    id='bears-like-this'
                    name='bears_like_this'
                    style={{display: 'none'}}
                    type='text'
                  />

                  <TentField label='Title' name='title' type='text' required={true} />
                  <TentField label='Company' name='company' type='text' required={true} />
              </div>
            </div>

            <div className="grid">
              <div className="grid__column">
                <TentField label='Address 1' name='address_1' type='text' required={true} />
              </div>
            </div>

            <div className="grid">
              <div className="grid__column">
                <TentField label='Address 2' name='address_2' type='text' required={false} />
              </div>
            </div>

            <div className="grid">
              <div className="grid__column">
                <TentField label='City' name='city' type='text' required={true} />
              </div>
            </div>

            <div className="grid">
              <div className="grid__column">
                <TentField label='State' name='state' type='text' required={true} />
              </div>
              <div className="grid__column">
                <TentField label='Zip Code' name='zip_code' type='text' required={true} />
              </div>
            </div>

            <div className="grid">
              <div className="grid__column">
                <TentField label='Phone' name='phone' type='text' required={true} />
              </div>
              <div className="grid__column">
                <TentField label='Email' name='email' type='text' required={true} />
              </div>
            </div>

            <div className="grid">
              <div className="grid__column">
                <TentField label='Website' name='website' type='text' required={false} />
              </div>
            </div>

            <div className="grid">
              <div className="grid__column grid__column--12 grid__column--4--md">
                <label className="control__label" htmlFor="company_type">Type of Company*</label>
                <div className="control control--block control--select">
                  <Field
                    aria-required={true}
                    className="control__select"
                    component="select"
                    id="company_type"
                    name="company_type"
                    required={true}
                  >
                    <option disabled value="">Please Select One</option>
                    <option value="Architect">Architect</option>
                    <option value="Structural Engineer">Structural Engineer</option>
                    <option value="General Contractor">General Contractor</option>
                    <option value="Developer/Owner">Developer/Owner</option>
                    <option value="Other">Other</option>
                  </Field>
                </div>
              </div>

              <div className="grid__column grid__column--12 grid__column--4--md">
                <label className="control__label" htmlFor="project_type">Project Type</label>
                <div className="control control--block control--select">

                  <Field
                    className="control__select"
                    component="select"
                    id="project_type"
                    name="project_type"
                  >
                    <option disabled value="">Please Select One</option>
                    <option value="Apartments">Apartments</option>
                    <option value="Condos">Condos</option>
                    <option value="Lofts">Lofts</option>
                    <option value="Hotels">Hotels</option>
                    <option value="Senior Housing">Senior Housing</option>
                    <option value="Student Housing">Student Housing</option>
                    <option value="Other">Other</option>
                  </Field>
                </div>
              </div>

              <div className="grid__column grid__column--12 grid__column--4--md">
                <label className="control__label" htmlFor="project_size">Project Size</label>
                <div className="control control--block control--select">
                  <Field
                    className="control__select"
                    component="select"
                    id="project_size"
                    name="project_size"
                  >
                    <option disabled value="">Please Select One</option>
                    <option value="Less Than 50,000 SF">Less Than 50,000 SF</option>
                    <option value="50,000 to 100,000 SF">50,000 to 100,000 SF</option>
                    <option value="100,000 to 200,000 SF">100,000 to 200,000 SF</option>
                    <option value="Over 200,000 SF">Over 200,000 SF</option>
                  </Field>
                </div>
              </div>
            </div>

            <div className="grid">
              <div className="grid__column">
                <span className="type-bold">Information Requested</span>
              </div>
            </div>

            <div className="grid">
              <div className="grid__column grid__column--12 grid__column--4--md">
                <div className="control control--checkbox">
                  <label className="control__label">
                    <Field
                      className="control__checkbox"
                      component="input"
                      name="info"
                      type="checkbox"
                      value="Personal Contact"
                    />
                    Personal Contact
                  </label>
                </div>
              </div>
              <div className="grid__column grid__column--12 grid__column--4--md">
                <div className="control control--checkbox">
                  <label className="control__label">
                    <Field
                      className="control__checkbox"
                      component="input"
                      name="info"
                      type="checkbox"
                      value="Design Binder"
                    />
                    Design Binder
                  </label>
                </div>
              </div>

              <div className="grid__column grid__column--12 grid__column--4--md">
                <div className="control control--checkbox">
                  <label className="control__label">
                    <Field
                      className="control__checkbox"
                      component="input"
                      name="info"
                      type="checkbox"
                      value="Catalog"
                    />
                    Catalog
                  </label>
                </div>
              </div>
            </div>

            <div className="grid">
              <div className="grid__column">
                <div className="control control--block">
                  <label htmlFor="comments" className="control__label">Comments</label>
                  <Field
                    className="control__textarea control__textarea--md"
                    component="textarea"
                    id="comments"
                    name="comments"
                  />
                </div>
              </div>
            </div>

            <div className="grid">
              <div className="grid__column">
                <div className="control">
                  <ReCAPTCHA
                    id='actual_recaptcha'
                    sitekey="6Lc4bIoUAAAAAHopZ9L82jSGcT5R3RoXlXlo98I9"
                    onChange={(value) => form.mutators.setReCaptcha(value)}
                    onExpired={() => form.mutators.clearReCaptcha()}
                    onErrored={() => form.mutators.clearReCaptcha()}
                  />
                  <Field
                    component="input"
                    id="recaptcha"
                    name="recaptcha"
                    type="hidden"
                  />
                  <br />
                </div>
              </div>
            </div>

            <div className="grid">
              <div className="grid__column">
                <div className="control">
                  <button className='button button--primary' disabled={submitting} type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  </Layout>);
}


export const pageQuery = graphql`
    query {
        iBrochure: file(relativePath: { eq: "request-info/brochure.png" }) {
            childImageSharp {
                fluid(maxWidth: 268) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
    }
`

